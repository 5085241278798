.App {
  text-align: center;
  margin: 0 auto;
  max-width: 1000px;
  height: auto;
  padding: 40px 4rem;
  background: url('./assets/img/bg1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

 @media (max-width: 768px) {
  .App {
    padding: 20px;
    width: 750px;
  }
}

@media (max-width: 520px) {
  .App {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 320px) {
  .App {
    max-width: 300px;
  }
} 




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
