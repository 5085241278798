.Footer {
    margin: 0 auto;
    margin-top: 20px;
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    color: var(--secondary-color);
}
  
.FooterGithub svg {
    transition: 0.4s ease all;
    fill: var(--secondary-color);

}

.FooterGithub svg:hover {
    fill: var(--primery-color);
}

@media (max-width: 520px) {
    .Footer {
        width: 100%;
    }
}