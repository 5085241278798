.InputRow {
    position: relative;
}

.Input {
    border: none;
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
    margin-top: 20px;
    line-height: 2rem;
    color: #979292;
    font-size: 1.5rem;
    background-color: black;
    border-bottom: 2px solid var(--primery-color);
}

.Input:focus {
    outline: none;
}
.Input:active {
    background-color: black;
}
.Input::placeholder {
    color: rgba(151, 146, 146, 0.6);
    font-size: 1.5rem;
}

.searchIcon {
    position: absolute;
    top: 35px;
    right: 5px;
    width: 30px;
    height: 30px;
    color: var(--secondary-color);
    transition: 0.4s; 
}   

.searchIcon:hover {
    cursor: pointer;
    color: var(--primery-color);
}


@media (max-width: 768px) {
    .Input {
        line-height: 2.5rem;
    }
}
  
@media (max-width: 520px) {
    .InputRow {
        width: 100%;
    }
    .Input {
        line-height: 2rem;
        font-size: 0.8rem;
    }
    .Input::placeholder {
        font-size: 0.8rem;
    }
    .searchIcon {
        top: 35px;
        right: 0;
    }
}
