.SearchOptions {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.SearchBy {
    width: 30%;
    color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
}

.SearchByCheckboxes {
    display: flex;
    gap: 15px;
}

@media (max-width: 924px) {
    .SearchBy {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .SearchBy {
        width: 50%;
    }
}

@media (max-width: 520px) {
    .SearchOptions {
        flex-direction: column;
        gap: 15px;
    }
    .SearchBy {
        flex-direction: column;
        order: 1;
        font-size: 0.8rem;
        gap: 8px;
        width: 100%;
    }
    .SearchByCheckboxes {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}

