.MovieModal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
    transition: 0.4s all;
}
.hidden {
    top: -100%;
}
.ModalInfo {
    position: absolute;
    width: 700px;
    height: 500px;
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    color: #ffffff;
    text-align: justify;
    border: 10px solid var(--secondary-color);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ModalInfo:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(68, 25, 25, 0.6);
    z-index: 2;
}

.ModalInfo > 
  p,
  h3 {
    z-index: 3;
}

.MovieDescr {
    flex-basis: 100px;
}

.ModalClose {
    text-align: right;
    z-index: 3;
}

.MovieBudget {
    z-index: 3;
}

@media (max-width: 520px) {
    .ModalInfo{
        width: 90%;
        height: auto
    }
}