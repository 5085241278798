.MovieResultsRow {
    margin-top: 20px;
    padding: 8px 4px;
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary-color);
    align-items: center;
}

.MovieSortBtn {
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
}

.MovieSortBtnCheckboxes {
    display: flex;
    gap: 20px;
}

.MovieFound {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: left;
}


@media (max-width: 850px) {
    .MovieSortBtn {
        width: 55%;
    }
    .MovieResultsRow {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .MovieFound {
        line-height: 1.1rem;
    }
}

@media (max-width: 520px) {
    .MovieResultsRow {
        flex-direction: column;
        gap: 15px;
    }
    .MovieSortBtn {
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    .MovieSortBtnCheckboxes {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}