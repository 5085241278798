* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

:root {
  --primery-color: #f75163; 
  --secondary-color: #DCDBDB;
}

@media (max-width: 768px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 520px) {
  html {
    font-size: 24px;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 20px;
  }
}
