.MovieContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;
    row-gap: 1.8rem;
    padding: 0 15px;
}

/* @media (max-width: 768px) {

} */
@media (max-width: 520px) {
    .MovieContainer {
        justify-content: center;
        padding: 0;
    }
}