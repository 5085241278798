.HeaderName {
    font-size: 1rem;
    text-align: left;
    text-transform: lowercase;
    color: var(--primery-color);
    font-weight: 400;
}
.HeaderTitle {
    margin-top: 40px;
    color: var(--secondary-color);
    text-transform: uppercase;
    text-align: left;
    font-weight: 700;
    font-size: 2rem;
}

@media (max-width: 520px) {
    .HeaderTitle {
        font-size: 1.3rem;
    }
    .HeaderName {
        font-size: .7rem;
    }
}