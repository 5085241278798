.container {
    display: block;
    position: relative;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    text-transform: uppercase;
    padding: 4px 8px;
    font-size: 1rem;
    background-color: #3D3E3E;
    color: var(--secondary-color);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.4s ease all;
}

.container:hover input ~ .checkmark{
    transform: scale(1.02);
    background-color: var(--secondary-color);
    color: #3D3E3E;
}

.container input:checked + .checkmark{
    background-color: var(--primery-color);
}

@media (max-width: 768px) {
    .checkmark {
        font-size: 1.1rem;
    }
}
@media (max-width: 520px) {
    .checkmark {
        font-size: 0.8rem;
    }
}

@media (max-width: 320px) {
    .checkmark {
        font-size: 0.8rem;
    }
}
